<template>
    <div>




        <div class="main_content align-items-center">
            <div class="row justify-content-between">
                <div class="col-md-12 d-lg-none d-md-none">
          <div id="example-1">
            <button class="btn-burger" @click="show = !show"> <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 35.575 38.631" style=""><g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)"><path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path><path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path></g></svg>    {{btnText}}</button>
            <transition name="slide">
              <aside v-show="show">
                     <div class="text-end">
                        <button class="btn-burger" @click="show = !show">X {{btnText}}</button>
                      </div>

                    <div class="anotherSide">
                           <ul class="nav flex-column">
        <li class="nav-item" @click="show = !show">
          <router-link :class="
                      $route.name == 'my_reports'
                        ? 'router-link active'
                        : 'router-link'
                    " class="nav-link" :to="{ name: 'my_reports' }" aria-current="page">
            <span data-feather="home">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="Path_113" data-name="Path 113" d="M13,21V11h8V21ZM3,13V3h8V13Zm6-2V5H5v6ZM3,21V15h8v6Zm2-2H9V17H5Zm10,0h4V13H15ZM13,3h8V9H13Zm2,2V7h4V5Z" transform="translate(-3 -3)" fill=" " />
              </svg>
            </span> My Reports
          </router-link>
        </li>
        <li class="nav-item" @click="show = !show">
          <router-link :class="
                      $route.name == 'all_user' || $route.name =='help_center'
                        ? 'router-link active'
                        : 'router-link'
                    " class="nav-link" :to="{ name: 'help_center' }" aria-current="page">
            <span data-feather="home" class="help-center stroke_icon">
               <svg id="UI-Part" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                <g id="lifebuoy">
                  <rect id="Rectangle" width="23" height="23" fill="none" />
                  <circle id="Oval" cx="8.625" cy="8.625" r="8.625" transform="translate(2.875 2.875)" fill="#000" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <circle id="Oval-2" data-name="Oval" cx="3.594" cy="3.594" r="3.594" transform="translate(7.906 7.906)" fill="#fff" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path" x1="3.558" y1="3.558" transform="translate(5.4 5.4)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-2" data-name="Path" y1="3.558" x2="3.558" transform="translate(14.043 5.4)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-3" data-name="Path" x2="3.558" y2="3.558" transform="translate(14.043 14.043)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-4" data-name="Path" x1="3.558" y2="3.558" transform="translate(5.4 14.043)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
              </svg>
            </span> Help Center
          </router-link>
        </li>
      </ul>
                    </div>

              </aside>
            </transition>
          </div>
        </div>
                <div class="col-sm-8">
                    <div class="d-lg-flex justify-content-between">
                        <div class="heading">
                            <h2>Help Center</h2>
                            <h3>Employee Dashboard</h3>
                        </div>
                        <div class="search_bar d-none">
                            <form action="">
                                <input type="text" placeholder="How Can We Help You?" class="form-control" />
                                <a href="#" class="search_icon"><img src="../../../assets/images/dashboard/Search_gray.svg"
                                        alt="img" /></a>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-sm-end mt-md-0 mt-2">

            </div>



            <div class="first_section dashboard_show hlp_center">

                <div class="row">
                    <div class="col-lg-8">

                        <div class="client_accordian">
                            <div class="accordion" id="accordionExample">
                                <div class="card_heading mt_40 mb_30">
                                    <h4 class="heading2">General Ergo</h4>
                                </div>
                                 <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT3">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT3"
                                                aria-expanded="false" aria-controls="collapseT3">
                                                Welcome to the employee ergo portal.
                                            </button>
                                        </h2>
                                        <div id="collapseT3" class="accordion-collapse collapse"
                                            aria-labelledby="headingT3" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w1.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30">Congratulations! And welcome to the beginning of a better you! The ergo portal is a robust platform that offers a variety of resources at your fingertips designed to help you feel and work at your best. Some of the features include:</p>
                                                        <ul class="text_balticsea helpcenter" style="margin: 0;padding: 0 20px;line-height: 1.5;">
                                                            <li> An Ergonomic Self-Assessment </li>
                                                            <li> Set-up Guidelines For Wherever You Are Working </li>
                                                            <li> Productivity & Focus Tips </li>
                                                            <li>  Everyday Stretches </li>
                                                            <li> Posture Perfect Exercises </li>
                                                            <li> Wellness Strategies</li>
                                                        </ul>
                                                        <!-- <div class="text-center w-100 mt_10">
                                                            <button class="btn btn_primary"> Watch Video Now </button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT2">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT2"
                                                aria-expanded="false" aria-controls="collapseT2">
                                                How many times can I take the self-assessment?
                                            </button>
                                        </h2>
                                        <div id="collapseT2" class="accordion-collapse collapse"
                                            aria-labelledby="headingT2" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/help_center_img_1.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30"><strong>Great news!</strong> You are welcome to take the self-assessment as many times as you want or need to! It is designed to help you assess your work area and habits in a variety of environments.</p>
                                                        <p class="text_balticsea mb_10">Here are some ideas of when you should consider taking a self-assessment:</p>
                                                          <ul class="text_balticsea helpcenter" style="margin: 0;padding: 0 20px;line-height: 1.5;">
                                                            <li> To make sure your work set-up is working for you. (Wherever that is these days!) </li>
                                                            <li> If there are any changes to your work environment or comfort levels. </li>
                                                            <li> As a periodic refresher to keep you on your game!</li>
                                                           
                                                        </ul>
                                                         <div class="text-center w-100 mt_20">
                                                            <a class="btn btn_red" href="self_assessment_signup"> Take an Assessment</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                               Is there a limit on how many times I can visit the site?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w3.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20 ml_20">
                                                        <p class="text_balticsea mb_30">
                                                            <strong>Nope!</strong> This is your ergo portal that is available to you 24/7 as long as you are an active employee at your current organization. </p>
                                                        <p class="text_balticsea">In fact, we encourage you to come to visit the site on a regular basis and take advantage of all of the great resources available to you!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              

                               

                               

                                <div class="card_heading mt_40 mb_30">
                                    <h4 class="heading2">My Reports</h4>
                                </div>

                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT4">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT4"
                                                aria-expanded="false" aria-controls="collapseT4">
                                                When will my ergo reports be available?
                                            </button>
                                        </h2>
                                        <div id="collapseT4" class="accordion-collapse collapse"
                                            aria-labelledby="headingT4" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w7.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30"><strong>For Self-Assessments,</strong> you will receive your report within a few minutes of completing your assessment. Once you hit submit, the system will send you an email letting you know that your report is waiting for your review in the portal profile found on the ergo site.</p>
                                                        <p class="text_balticsea"><strong>For 1:1 Ergonomic Evaluations,</strong> you will receive your report within 2 - 5 business days. This allows your ergonomist to look everything over and review your report before sending it to you. You will receive an email when it is ready for you to view in your portal profile, found on the ergo site.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT5">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT5"
                                                aria-expanded="false" aria-controls="collapseT5">
                                                How do I view my ergo report?
                                            </button>
                                        </h2>
                                        <div id="collapseT5" class="accordion-collapse collapse"
                                            aria-labelledby="headingT5" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w8.png"
                                                            alt="img">
                                                        </div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30"><strong>It’s easy!</strong> After completing your self-assessment or your 1:1 Ergonomic Evaluation with an ergonomist, you will receive a summary report that contains your findings and recommendations. </p>
                                                        <p class="text_balticsea">Your summary reports can be found in your portal profile found at the top right corner of the ergo site. </p>
                                                            <img src="../../../assets/images/dashboard/help_center.png" alt="" class="w-75">
                                                        <p class="text_balticsea">You will find the full history of all summary reports in your portal profile. </p>
                                                     <!-- <div class="text-center w-100 mt_20">
                                                            <button class="btn btn_primary"> Watch Video Now </button>
                                                        </div> -->
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT6">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT6"
                                                aria-expanded="false" aria-controls="collapseT6">
                                                How do I download my ergo report?
                                            </button>
                                        </h2>
                                        <div id="collapseT6" class="accordion-collapse collapse"
                                            aria-labelledby="headingT6" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w9.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_0"><strong> All of your ergo summary reports </strong> are automatically saved in your portal profile. If you want to download your reports, you can do so by clicking on the download icon and your report can be saved on your computer as a PDF file. </p>
                                                        <!-- <div class="text-center w-100 mt_20">
                                                            <button class="btn btn_primary"> Watch Video Now </button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card_heading mt_40 mb_30">
                                    <h4 class="heading2">My Data</h4>
                                </div>

                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT7">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT7"
                                                aria-expanded="false" aria-controls="collapseT7">
                                                Is my data secure?
                                            </button>
                                        </h2>
                                        <div id="collapseT7" class="accordion-collapse collapse"
                                            aria-labelledby="headingT7" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w4.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30"><strong>Yes,</strong> all data is stored and transmitted in compliance with HIPAA, GDPR, CCPA and, PIPL regulations, including standard levels of encryption. </p>
                                                        <p class="text_balticsea">For more information please see the <a href="/user/privacy_policy"><strong style="color:#39353D;">Privacy Policy.</strong></a> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT8">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT8"
                                                aria-expanded="false" aria-controls="collapseT8">
                                                Who sees my data?
                                            </button>
                                        </h2>
                                        <div id="collapseT8" class="accordion-collapse collapse"
                                            aria-labelledby="headingT8" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w5.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30">Your information may be shared with designated members of your organization for internal use only. We will never sell or share your information with any outside organization. </p>
                                                        <p class="text_balticsea">For more information please see the <a href="/user/privacy_policy"><strong style="color:#39353D;">Privacy Policy.</strong></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT9">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT9"
                                                aria-expanded="false" aria-controls="collapseT9">
                                                How do I opt out? (GDPR, PIPL, etc)
                                            </button>
                                        </h2>
                                        <div id="collapseT9" class="accordion-collapse collapse"
                                            aria-labelledby="headingT9" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w6.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_30">Should you want to exercise your right to alter or remove your identifying information, please contact us at <a href="mailto:support@pbergo.com"><strong style="color:#39353D;">support@pbergo.com </strong></a> </p>
                                                        <p class="text_balticsea">For more information please see the <a href="/user/privacy_policy"><strong style="color:#39353D;">Privacy Policy.</strong></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card_heading mt_40 mb_30">
                                    <h4 class="heading2">Additional Support</h4>
                                </div>

                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT10">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT10"
                                                aria-expanded="false" aria-controls="collapseT10">
                                                What if I need further help
                                            </button>
                                        </h2>
                                        <div id="collapseT10" class="accordion-collapse collapse"
                                            aria-labelledby="headingT10" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w10.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_0"><strong>If you need further support,</strong> please refer to your ergo summary report found in your portal profile. Your report will let you know how to request further support within your organization. </p>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT11">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT11"
                                                aria-expanded="false" aria-controls="collapseT11">
                                               How do I request an ergo eval?
                                            </button>
                                        </h2>
                                        <div id="collapseT11" class="accordion-collapse collapse"
                                            aria-labelledby="headingT11" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w11.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_0"><strong>If you are in need of an ergonomic evaluation,</strong> please refer to your ergo summary report found in your portal profile. Your report will let you know how to request further support within your organization. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" card add_client">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingT12">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseT12"
                                                aria-expanded="false" aria-controls="collapseT12">
                                               If equipment is recommended, what do I do next?
                                            </button>
                                        </h2>
                                        <div id="collapseT12" class="accordion-collapse collapse"
                                            aria-labelledby="headingT12" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">

                                                <div class="claim_box d-flex">
                                                    <div class="claim_text_image_box">
                                                        <img src="../../../assets/images/dashboard/w12.png"
                                                            alt="img"></div>
                                                    <div class="claim_text_box ml_20">
                                                        <p class="text_balticsea mb_0"><strong>If the equipment was recommended </strong> as a result of your self-assessment or 1:1 ergonomic evaluation, please refer to your ergo summary report found in your portal profile. Your report will list any equipment recommendations as well as for instructions on what to do next.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card site_footer mb_50 border_radius_20">
                            <div class="card_heading">
                                <h4 class="heading2">Contact Us</h4>
                            </div>
                            <!-- <div class="row ">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label"></label>
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label"> </label>
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label"></label>
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Subject">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="form-label"></label>
                                        <textarea class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12 text-start">
                                    <a href="javascript:void(0);" class="btn btn_primary">Submit</a>
                                </div>
                            </div> -->
                            <iframe loading="lazy" src="https://pbergo.sjc1.qualtrics.com/jfe/form/SV_72sHB7wMC0VNSYK" height="510px" width="100%"></iframe>

                        </div>
                    </div>
                </div>
            </div>




        </div>


    </div>
</template>

<script>
    export default {
        name: "airbnb_help_center",
        el: '#example-1',
        computed: {
		btnText: function(){if(this.show){  
		return ''}
		return ''}
	},
    data() {
     return {
      show: false,
     };
  },
        destroyed() {
            this.$parent.employeeDashboard = false
            // this.$router.go()
        },
        methods:{
    reloadPage() {
         
      window.location.reload();
    }
  },
        mounted() {
            this.$parent.employeeDashboard = true;
        }
        
    }

// function reload(){
//    if (window.location.href.substr(-2) !== "?r") {
//         window.location = window.location.href + "?r";
//     }
// }
// setTimeout(reload, 100);
</script>
